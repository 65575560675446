import { RealmConfig } from "../Type";
import LoginImage from "ui/assets/svg/Login.svg";
import RegisterImage from "ui/assets/svg/Register.svg";
import namazLogo from "ui/assets/img/islamicdl_logo.png";
import { defaultConfig } from "config/default";
import ResetPasswordImage from "ui/assets/svg/ResetPassword.svg";
import UpdatePasswordImage from "ui/assets/svg/UpdatePassword.svg";

export const namazConfig: RealmConfig = {
    extraFields: {},
    messages: {
        loginRightSectionHeader: {
            fa: "وارد شوید",
            en: "Let's Login",
        },
        loginRightSectionText: {
            fa: "با ورود به سامانه، میتوانید به تمامی سرویس های ارائه شده دسترسی پیدا کنید",
            en: "With Logging In You Can Access All Of The Services We Provide",
        },
        registerRightSectionHeader: {
            fa: "ثبت نام کنید",
            en: "Let's Register",
        },
        registerRightSectionText: {
            fa: "امکان دسترسی هر کاربر به تمامی سرویس های یک سازمان، با عنوان کاربری و کلمه عبور اختصاصی و یکسان",
            en: "ability to access organization services with unique username and password",
        },
        forgotPasswordRightSectionHeader: {
            en: "",
            fa: "",
        },
        forgotPasswordRightSectionText: {
            en: "",
            fa: "",
        },
    },
    termsLink: "https://ketab.namaz.ir/fa/terms-of-service",
    logo: namazLogo,
    pages: {
        login: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "loginRightSectionHeader",
            rightSectionText: "loginRightSectionText",
            rightSectionImage: LoginImage,
        },
        register: {
            leftToRightRatio: 1.9783,
            rightSectionHeader: "registerRightSectionHeader",
            rightSectionText: "registerRightSectionText",
            rightSectionImage: RegisterImage,
        },
        resetPassword: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "forgotPasswordRightSectionHeader",
            rightSectionText: "forgotPasswordRightSectionText",
            rightSectionImage: ResetPasswordImage,
        },
        updatePassword: {
            leftToRightRatio: 0.9935,
            rightSectionHeader: "forgotPasswordRightSectionHeader",
            rightSectionText: "forgotPasswordRightSectionText",
            rightSectionImage: UpdatePasswordImage,
        },
    },
    palette: {
        onyxia: {
            focus: {
                main: "#777",
                light: "#777",
                light2: "#777",
            },
            dark: {
                main: "#000",
                light: "#000",
                greyVariant1: "#000",
                greyVariant2: "#000",
                greyVariant3: "#000",
                greyVariant4: "#bcbdbf",
            },
            light: {
                main: "#000",
                light: "#000",
                greyVariant1: "#000",
                greyVariant2: "#000",
                greyVariant3: "#000",
                greyVariant4: "#bcbdbf",
            },
            redError: defaultConfig.palette.onyxia.redError,
            greenSuccess: defaultConfig.palette.onyxia.greenSuccess,
            orangeWarning: defaultConfig.palette.onyxia.orangeWarning,
            blueInfo: defaultConfig.palette.onyxia.blueInfo,
            "limeGreen": {
                "main": "#6B02BB",
                "light": "#6B02BB",
            },
            "agentConnectBlue": {
                "main": "#0579EE",
                "light": "#2E94FA",
                "lighter": "#E5EDF5",
            },
        },
        main: {
            primary: defaultConfig.palette.main.primary,
            secondary: {
                main: "#04bfbf",
                dark: "#06a1a1",
                "100": "#43cfcf",
                "200": "#6B02BB",
                light: "#c0efef",
            },
            success: {
                main: "#1ab297",
                dark: "#0a967d",
                "100": "#53c5b0",
                "200": "#8dd9cb",
                light: "#c6ece5",
            },
            info: {
                main: "#03b9ff",
                dark: "#00a5e5",
                "100": "#42cbff",
                "200": "#81dcff",
                light: "#c0eeff",
            },
            error: {
                main: "#f36d53",
                dark: "#dc553b",
                "100": "#f6927e",
                "200": "#f8b5a8",
                light: "#fcdad4",
            },
            warning: {
                main: "#f5d650",
                dark: "#ecc622",
                "100": "#f7e07b",
                "200": "#faeaa7",
                light: "#fcf4d4",
            },
            grey: defaultConfig.palette.main.grey,
        },
    },
};
